var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"posDevices",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',{class:{ 'mobile-card': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-card-title',{staticClass:"d-flex",staticStyle:{"vertical-align":"middle"}},[_c('v-row',{staticClass:"pa-3 mb-2"},[_c('h3',{staticClass:"text--secondary"},[_vm._v("Terminali")]),_c('v-spacer')],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"pb-0 mb-0"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"3"}},[_c('v-select',{staticClass:"pt-0 mt-0 pb-0 mb-0",attrs:{"outlined":"","dense":"","label":"Prodajna mjesta","items":_vm.locations,"item-text":"name","item-value":"id","return-object":"","rules":[_vm.rules.req]},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"3"}},[_c('v-btn',{on:{"click":function($event){$event.stopPropagation();return _vm.openAddDevice()}}},[_vm._v("Dodaj terminal")])],1)],1),_c('v-row',{staticClass:"flex-wrap justify-left"},[(_vm.sortedDevices && _vm.sortedDevices.length > 0)?_c('v-expansion-panels',{attrs:{"focusable":""}},_vm._l((_vm.sortedDevices),function(device,i){return _c('v-expansion-panel',{key:i,attrs:{"hover":"","tabindex":"0"}},[_c('v-expansion-panel-header',{class:device.status === 'ACTIVATED'
                  ? ['status-ok-exp']
                  : ['status-failed-exp'],attrs:{"height":"44"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"pl-1 caption grey--text"},[_vm._v("Uređaj")]),_c('div',{staticClass:"pl-1 text-capitalize"},[_vm._v(_vm._s(device.name))])]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"pl-1 caption grey--text"},[_vm._v("POS")]),_c('div',{staticClass:"pl-1 text-capitalize"},[_vm._v(" "+_vm._s(device.type_name)+" ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"pl-1 caption grey--text"},[_vm._v("Status")]),_c('div',{staticClass:"pl-1 text-capitalize"},[_vm._v(" "+_vm._s(_vm.showStatus(device.status))+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{staticClass:"d-flex align-end justify-end"},[_c('div',{staticClass:"d-flex align-end justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3 pt-0",attrs:{"icon":""},on:{"click":[function($event){$event.stopPropagation();return _vm.openEditDevice(device)},function($event){return _vm.openEditDevice(device)}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editiraj uređaj")])])],1),_c('div',{staticClass:"d-flex align-end justify-end pr-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3 pt-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteDevice(device)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Obriši uređaj")])])],1)])],1)],1)],1),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"pl-1 caption grey--text"},[_vm._v("Tip POS-a")]),_c('div',{staticClass:"pl-1 text-capitalize"},[_vm._v(" "+_vm._s(_vm.pos.type_name)+" ")])]),_c('v-col',[_c('div',{staticClass:"pl-1 caption grey--text"},[_vm._v("Id uređaja")]),_c('div',{staticClass:"pl-1 text-capitalize"},[_vm._v(" "+_vm._s(device.device_id)+" ")])]),_c('v-col',[_c('div',{staticClass:"pl-1 caption grey--text"},[_vm._v("Kartice")]),_c('div',{staticClass:"pl-1 text-capitalize"},[_vm._v(" "+_vm._s(_vm.showCards(device.cards))+" ")])]),_c('v-col',{attrs:{"cols":"8"}})],1)],1)],1)}),1):_vm._e()],1)],1)],1),_c('add-pos-device',{ref:"addPosDevice",on:{"added":_vm.refreshAdded}}),_c('edit-pos-device',{ref:"editPosDevice",on:{"addedDevice":_vm.refreshAll}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }