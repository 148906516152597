<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="300"
    style="z-index: 6"
  >
    <v-form ref="addPosForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">
              {{ type && type === "VIVA" ? "POS API vjerodajnice" : "POS" }}
            </h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="flex-wrap justify-center">
            <v-col>
              <!-- <v-row class="pt-0 mt-2 pb-0 mb-0">
                <v-col class="pt-0 mt-2 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    label="Naziv uređaja"
                    v-model="name"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row> -->
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-2 pb-0 mb-0">
                  <v-select
                    class="pt-0 mt-0 pb-0 mb-0"
                    outlined
                    dense
                    label="Tip POS-a"
                    v-model="type"
                    :items="poses"
                    item-text="name"
                    return-object
                    :rules="[rules.req]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row
                v-if="type && type.name !== 'TABLEPOP'"
                class="pt-0 mt-0 pb-0 mb-0"
              >
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    :label="
                      type && type.name === 'VIVA'
                        ? 'ID Klijenta'
                        : type.name === 'MYPOS'
                        ? 'Korisničko ime'
                        : ''
                    "
                    v-model="username"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                class="pt-0 mt-0 pb-0 mb-0"
                v-if="type && type.name !== 'TABLEPOP'"
              >
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    :label="
                      type && type.name === 'VIVA'
                        ? 'Tajni ključ'
                        : type.name === 'MYPOS'
                        ? 'Lozinka'
                        : ''
                    "
                    v-model="password"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                class="pt-0 mt-0 pb-0 mb-0"
                v-if="type && type.name === 'VIVA'"
              >
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    label="Webhook ključ"
                    v-model="webhookKey"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Dodaj POS
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
// import clone from '@/plugins/utils'

export default {
  components: { },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    location: undefined,
    submitting: false,
    username: undefined,
    password: undefined,
    type: undefined,
    webhookKey: undefined,
    poses: [],
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: []
  }),
  mounted () {

  },
  methods: {
    open (locationId, existingPos) {
      const docRef = df.doc('project_constants/poses')
      docRef.onSnapshot(doc => {
        var poses = Object.values(doc.data().poses)
        poses.forEach((pos, index) => {
          var found = false
          existingPos.forEach(existingPos => {
            if (existingPos.type_id === pos.id) {
              found = true
            }
          })

          if (!found) {
            this.poses.push(pos)
          }
        })
      })
      this.visible = true
      this.submitting = false
      this.locationId = locationId
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.username = undefined
      this.password = undefined
      this.name = undefined
      this.type = undefined
      this.visible = false
      this.location = undefined
      this.webhookKey = undefined
    },
    async submit () {
      const tblId = uuidv4()
      const docRef = df.doc(`company_poses/${state.getCurrentCompany().id}/poses/${tblId}`)

      if (!this.$refs.addPosForm.validate()) {
        return
      }

      if (this.type.name === 'TABLEPOP') {
        this.username = ''
        this.password = ''
        this.webhookKey = ''
      }

      var posParams = {
        id: tblId,
        username: this.username,
        password: this.password,
        type_name: this.type.name,
        type_id: this.type.id
      }

      if (this.webhookKey && this.webhookKey !== '') {
        posParams.webhook_key = this.webhookKey
      }

      try {
        await docRef.set(
          posParams
        )
      } catch (err) {
        console.error('Error saving pos: ', err)
      }

      this.resetInputs()
    }
  }

}
</script>
