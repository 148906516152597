import { df } from '@/plugins/firebase'
import { toISOLocal } from '@/plugins/utils'

export const getMultiplier = async () => {
  const document = await df.doc('project_constants/config').get()
  const multiplier = document.data().item_amount.decimal_multiplier
  return multiplier
}

export const getPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case 'G':
      return 'NOVČANICE'
    case 'K':
      return 'KARTICA'
    case 'O':
      return 'OSTALO'
    case 'T':
      return 'TRANSAKCIJSKI RAČUN'
    case 'P':
      return 'TRANSAKCIJSKI RAČUN'
  }
}

export const getPosNote = async (state) => {
  const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
  if (register?.data()?.note_pos && register?.data()?.note_pos?.length > 0) {
    return register.data().note_pos
  } else {
    const pointOfSale = await df.doc(`locations/${state.getPointOfSale().id}`).get()
    if (pointOfSale?.data()?.note_pos && pointOfSale?.data()?.note_pos?.length > 0) {
      return pointOfSale.data().note_pos
    } else {
      const company = await df.doc(`companies/${state.getCurrentCompany().id}`).get()
      if (company?.data()?.note_pos && company?.data()?.note_pos?.length > 0) {
        return company.data().note_pos
      }
    }
  }
  return ''
}

export const dateTimeToUnixTimestamp = (dt) => {
  return Math.floor(new Date(toISOLocal(dt)).getTime() / 1000)
}
