<template>
  <v-form ref="posDevices" @submit.prevent="submit">
    <v-card :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
      <v-card-title class="d-flex" style="vertical-align: middle">
        <v-row class="pa-3 mb-2">
          <h3 class="text--secondary">Terminali</h3>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="pb-0 mb-0">
          <v-col cols="3" class="pb-0 mb-0">
            <v-select
              class="pt-0 mt-0 pb-0 mb-0"
              outlined
              dense
              label="Prodajna mjesta"
              v-model="location"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object
              :rules="[rules.req]"
              @change="getData()"
            ></v-select>
          </v-col>
          <v-col cols="3" class="pb-0 mb-0">
            <v-btn class="" @click.stop="openAddDevice()">Dodaj terminal</v-btn>
          </v-col>
        </v-row>
        <v-row class="flex-wrap justify-left">
          <v-expansion-panels
            v-if="sortedDevices && sortedDevices.length > 0"
            focusable
          >
            <v-expansion-panel
              v-for="(device, i) in sortedDevices"
              :key="i"
              hover
              tabindex="0"
            >
              <v-expansion-panel-header
                height="44"
                :class="
                  device.status === 'ACTIVATED'
                    ? ['status-ok-exp']
                    : ['status-failed-exp']
                "
              >
                <v-row>
                  <v-col cols="4">
                    <div class="pl-1 caption grey--text">Uređaj</div>
                    <div class="pl-1 text-capitalize">{{ device.name }}</div>
                  </v-col>

                  <v-col cols="3">
                    <div class="pl-1 caption grey--text">POS</div>
                    <div class="pl-1 text-capitalize">
                      {{ device.type_name }}
                    </div>
                  </v-col>

                  <v-col cols="3">
                    <div class="pl-1 caption grey--text">Status</div>
                    <div class="pl-1 text-capitalize">
                      {{ showStatus(device.status) }}
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <v-row class="d-flex align-end justify-end">
                      <div class="d-flex align-end justify-end">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mt-3 pt-0"
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click.stop="openEditDevice(device)"
                              @click="openEditDevice(device)"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Editiraj uređaj</span>
                        </v-tooltip>
                      </div>
                      <div class="d-flex align-end justify-end pr-3">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mt-3 pt-0"
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click.stop="openDeleteDevice(device)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Obriši uređaj</span>
                        </v-tooltip>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="4">
                    <div class="pl-1 caption grey--text">Tip POS-a</div>
                    <div class="pl-1 text-capitalize">
                      {{ pos.type_name }}
                    </div>
                  </v-col>

                  <v-col>
                    <div class="pl-1 caption grey--text">Id uređaja</div>
                    <div class="pl-1 text-capitalize">
                      {{ device.device_id }}
                    </div>
                  </v-col>
                  <v-col>
                    <div class="pl-1 caption grey--text">Kartice</div>
                    <div class="pl-1 text-capitalize">
                      {{ showCards(device.cards) }}
                    </div>
                  </v-col>
                  <v-col cols="8"> </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>
    </v-card>
    <add-pos-device @added="refreshAdded" ref="addPosDevice"></add-pos-device>
    <edit-pos-device
      @addedDevice="refreshAll"
      ref="editPosDevice"
    ></edit-pos-device>
  </v-form>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
import AddPosDevice from '@/modules/point-of-sale/components/pos-configuration/AddPosDevice'
import EditPosDevice from '@/modules/point-of-sale/components/pos-configuration/EditPosDevice'
import { dateTimeToUnixTimestamp } from '@/libs/receiptIssuing/commons'

// import clone from '@/plugins/utils'

export default {
  components: { AddPosDevice, EditPosDevice },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'confirm', 'confirmClose'],
  data: () => ({
    submitting: false,
    location: undefined,
    locations: [],
    poses: [],
    sortedDevices: [],
    posDevices: [],
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: []
  }),
  props: {
    pos: {
      type: Object,
      default: () => {}
    }
  },
  async mounted () {
    this.getPointsOfSale()
    const docRef = df.doc('project_constants/poses')
    docRef.onSnapshot(doc => {
      var poses = Object.values(doc.data().poses)
      poses.forEach((pos, index) => {
        this.poses.push(pos)
      })
    })
  },
  methods: {
    showCards (cards) {
      var cardText = ''
      if (cards && cards.length > 0) {
        cards.forEach((card, key) => {
          if (key === 0) {
            cardText = card.name
          } else {
            cardText += ', ' + card.name
          }
        })
      }
      return cardText
    },
    refreshAdded (location) {
      setTimeout(() => {
        this.location = location
        this.sortedDevices = []
        this.posDevices = []
        this.getData()
      }, 1000)
    },
    refreshAll (device) {
      setTimeout(() => {
        this.sortedDevices = []
        this.posDevices = []
        this.getData()
      }, 1000)
    },
    showStatus (status) {
      switch (status) {
        case 'ACTIVATED':
          return 'AKTIVAN'
        case 'DEACTIVATED':
          return 'NEAKTIVAN'
      }
    },
    async getPointsOfSale () {
      var that = this
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          that.locations.push(location)
          this.location = that.locations[0]
          this.getData()
        })
      })
    },
    openDeleteDevice (device) {
      this.confirm({
        title: 'Brisanje uređaja',
        message: `Jeste li sigurni da želite obrisati uređaj ${device.name}?`,
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'DA',
          cancelText: this.$t('$vuetify.close')
        }
      }).then(async (resp) => {
        this.confirmClose()

        if (resp) {
          const docRef = df.doc(`location_poses/${this.location.id}/poses/${device.id}`)

          try {
            await docRef.delete()
          } catch (err) {
            console.error('Error deleting device: ', err)
          }

          df.collection('cash_registers')
            .where(`terminals.${device.id}.id`, '==', device.id)
            .get()
            .then((documentSnapshots) => {
              if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
                documentSnapshots.docs.forEach((rec) => {
                  var cReg = rec.data()
                  const rID = uuidv4()

                  var terminals = []
                  delete cReg.terminals[device.id]

                  if (Object.keys(cReg.terminals).length > 0) {
                    Object.keys(cReg.terminals).forEach(term => {
                      terminals.push(cReg.terminals[term])
                    })
                  }

                  const pl = {
                    action: {
                      resource_id: cReg.id,
                      operation: 'update',
                      entity: 'cash_register',
                      params: {
                        company_id: cReg.company_id,
                        location_id: cReg.location_id
                      }
                    }
                  }

                  pl.action.params.terminals = terminals

                  const userId = auth.currentUser.uid

                  df.doc(`request/${rID}`)
                    .set({
                      user_id: `${userId}`,
                      device_id: 'web',
                      created: `${dateTimeToUnixTimestamp(new Date())}`,
                      type: 'cashregister',
                      payload: btoa(
                        unescape(encodeURIComponent(JSON.stringify(pl)))
                      )
                    })
                    .catch((err) => {
                      this.showMsgBox({
                        text: err && err !== '' ? err : 'An error has occurred',
                        actions: ['cancel'],
                        cancelBtnText: this.$t('$vuetify.close'),
                        color: 'error'
                      })
                    })
                })
              }
            })

          setTimeout(() => {
            this.sortedDevices = []
            this.posDevices = []
            this.getData()
          }, 1000)
        }
      })
    },
    openEditDevice (device) {
      this.$refs.editPosDevice.open(this.pos, this.location, device)
    },
    openAddDevice () {
      this.$refs.addPosDevice.open(this.pos, this.location)
    },
    close () {
      this.resetInputs()
      this.visible = false
    },
    resetInputs () {
      this.visible = false
      this.location = undefined
    },
    async getData () {
      if (this.location) {
        await df.collection(`location_poses/${this.location.id}/poses`)
          .where('type_id', '==', this.pos.type_id)
          .get()
          .then((documentSnapshots) => {
            if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
              this.sortedDevices = []
              const tempDevices = []
              documentSnapshots.docs.forEach((devices) => {
                tempDevices.push(devices.data())
              })
              this.sortedDevices = tempDevices
            }
          })
      }

      this.$forceUpdate()
    },
    async submit () {
      const tblId = uuidv4()
      const docRef = df.doc(`location_poses/${this.locationId}/poses/${tblId}`)

      try {
        await docRef.set(
          {
            id: tblId,
            username: this.username,
            password: this.password,
            type_name: this.type.name,
            type_id: this.type.id
          }
        )
      } catch (err) {
        console.error('Error saving pos: ', err)
      }

      this.resetInputs()
    }
  }

}
</script>
