<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="addPosForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">
              {{
                pos && pos.type_name && pos.type_name === "VIVA"
                  ? "POS API vjerodajnice"
                  : "POS"
              }}
            </h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text v-if="pos">
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-2 pb-0 mb-0">
                  <v-select
                    class="pt-0 mt-0 pb-0 mb-0"
                    outlined
                    dense
                    disabled
                    label="Tip POS-a"
                    v-model="po"
                    :items="poses"
                    item-text="name"
                    item-value="id"
                    return-object
                    :rules="[rules.req]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row
                v-if="pos && pos.type_name && pos.type_name !== 'TABLEPOP'"
                class="pt-0 mt-0 pb-0 mb-0"
              >
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    :label="
                      pos && pos.type_name && pos.type_name === 'VIVA'
                        ? 'ID Klijenta'
                        : pos.type_name === 'MYPOS'
                        ? 'Korisničko ime'
                        : ''
                    "
                    v-model="pos.username"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                class="pt-0 mt-0 pb-0 mb-0"
                v-if="pos && pos.type_name && pos.type_name !== 'TABLEPOP'"
              >
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    :label="
                      pos && pos.type_name && pos.type_name === 'VIVA'
                        ? 'Tajni ključ'
                        : pos.type_name === 'MYPOS'
                        ? 'Lozinka'
                        : ''
                    "
                    v-model="pos.password"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                class="pt-0 mt-0 pb-0 mb-0"
                v-if="pos && pos.type_name && pos.type_name === 'VIVA'"
              >
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    label="Webhook ključ
                    "
                    v-model="pos.webhook_key"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <!-- <v-btn
            v-if="pos && pos.type_name && pos.type_name !== 'TABLEPOP'"
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            @click="checkLoginData"
          >
            Provjeri podatke
          </v-btn> -->
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi izmjene
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import state from '@/state'

export default {
  components: { },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    location: undefined,
    submitting: false,
    pos: undefined,
    poses: [],
    po: undefined,
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: []
  }),
  mounted () {
  },
  methods: {
    checkLoginData () {

    },
    open (locationId, pos) {
      this.pos = pos
      this.po = {
        id: pos.type_id,
        name: pos.type_name
      }
      this.visible = true
      this.submitting = false
      this.locationId = locationId

      const docRef = df.doc('project_constants/poses')
      docRef.onSnapshot(doc => {
        var poses = Object.values(doc.data().poses)
        poses.forEach((pos, index) => {
          this.poses.push(pos)
        })
      })
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.term = undefined
      this.pos = undefined
      this.visible = false
      this.location = undefined
    },
    async submit () {
      if (!this.pos) {
        return
      }
      const tblId = this.pos.id
      const docRef = df.doc(`company_poses/${state.getCurrentCompany().id}/poses/${tblId}`)

      try {
        await docRef.set(
          {
            id: tblId,
            type_name: this.po.name,
            type_id: this.po.id,
            username: this.pos.username,
            password: this.pos.password,
            webhook_key: this.pos.webhook_key
          }
        )
      } catch (err) {
        console.error('Error saving pos: ', err)
      }

      this.resetInputs()
    }
  }

}
</script>
