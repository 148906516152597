<template>
  <div style="width: 100%" class="fill-height">
    <v-container>
      <v-row style="margin-top: 40px" class="d-flex justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(item, i) in sortedPoses"
              :key="i"
              hover
              tabindex="0"
            >
              <v-expansion-panel-header height="44" :class="['status-ok-exp']">
                <v-row>
                  <v-col cols="8" :class="[densityPadding]">
                    <div class="pl-1 caption grey--text">POS</div>
                    <div class="pl-1 text-capitalize">{{ item.type_name }}</div>
                  </v-col>
                  <v-col cols="4" :class="[densityPadding]">
                    <div class="d-flex align-end justify-end">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mt-0 pt-0"
                            :x-small="density === 'greater'"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="openEditPos(item)"
                            @click="openEditPos(item)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("$vuetify.company.editAssociate") }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="4">
                    <v-row
                      dense
                      class="mt-2"
                      v-if="item.type_name !== 'TABLEPOP'"
                    >
                      <v-col>
                        <div class="pl-1 caption grey--text">
                          {{
                            item.type_name === "VIVA"
                              ? "ID Klijenta"
                              : item.type_name === "MYPOS"
                              ? "Korisničko ime"
                              : ""
                          }}
                        </div>
                        <div class="pl-1 text-capitalize">
                          {{ item.username }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row
                      dense
                      class="mt-2"
                      v-if="item.type_name !== 'TABLEPOP'"
                    >
                      <v-col>
                        <div class="pl-1 caption grey--text">Lozinka</div>
                        <v-text-field
                          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPass ? 'text' : 'password'"
                          flat
                          solo
                          hide-details
                          :value="item.password"
                          @click:append="showPass = !showPass"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense class="mt-2" v-if="item.type_name === 'VIVA'">
                      <v-col>
                        <div class="pl-1 caption grey--text">Webhook ključ</div>
                        <v-text-field
                          flat
                          solo
                          hide-details
                          :value="item.webhook_key"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="8">
                    <v-row class="pt-10 pr-5">
                      <v-col
                        style="
                          text-decoration: underline;
                          cursor: pointer;
                          color: #2597c5;
                        "
                        class="justify-end align-end"
                        ><v-row justify="end" @click="openPosDevices(item)"
                          >Terminali</v-row
                        ></v-col
                      ></v-row
                    >
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <modal
        height="auto"
        width="80%"
        scrollable
        draggable
        resizable
        style="z-index: 200"
        name="posDevices"
        class="myTest"
      >
        <pos-devices :pos="selectedPos"></pos-devices>
      </modal>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <add-pos ref="addPos"></add-pos>
      <edit-pos ref="editPos"></edit-pos>
    </v-container>
  </div>
</template>
<script>
import state from '@/state'
import AddPos from '@/modules/point-of-sale/components/pos-configuration/AddPos'
import EditPos from '@/modules/point-of-sale/components/pos-configuration/EditPos'
import PosDevices from '@/modules/point-of-sale/components/pos-configuration/PosDevices'
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import InfiniteLoading from 'vue-infinite-loading'
import applicationSettings from '@/mixins/applicationSettings'

export default {
  name: 'PosConfiguration',
  mixins: [applicationSettings],
  components: { AddPos, InfiniteLoading, EditPos, PosDevices },
  data: () => ({
    location: undefined,
    poses: [],
    selectedPos: undefined,
    showPass: false,
    sortedPoses: [],
    listeners: []
  }),
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    openPosDevices (pos) {
      this.selectedPos = pos
      this.$modal.show('posDevices')
    },
    openAddPos () {
      this.$refs.addPos.open(state.getPointOfSale().id, this.poses)
    },
    openEditPos (pos) {
      this.$refs.editPos.open(state.getPointOfSale().id, pos)
    },
    modifyPoses (changedPos) {
      let found = false
      this.poses.forEach((pos, key) => {
        if (pos.id === changedPos.id) {
          found = true
          this.poses[key] = changedPos
        }
      })

      if (!found) {
        this.poses.push(changedPos)
      }

      this.sortedPoses = clone(this.poses)
    },
    infiniteHandler ($state) {
      if (state.getPointOfSale().id !== '') {
        const query = df.collection(`company_poses/${state.getCurrentCompany().id}/poses`)
        const listener = query
          .onSnapshot((doc) => {
            doc.docs.forEach((rec) => {
              this.modifyPoses(rec.data())
            })
            $state.complete()
          })
        this.listeners.push(listener)
      } else {
        $state.complete()
      }
    }
  }
}
</script>
<style scoped>
.myTest >>> .vm--modal {
  top: 100px !important;
}
</style>
