<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    max-width="330"
    style="z-index: 6"
  >
    <v-form ref="addPosDeviceForm" @submit.prevent="submit">
      <v-card :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Terminal</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row class="pt-0 mt-2 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-select
                    class="pt-0 mt-0 pb-0 mb-0"
                    outlined
                    dense
                    label="Prodajno mjesto"
                    v-model="location"
                    :items="locations"
                    item-text="name"
                    item-value="id"
                    return-object
                    :rules="[rules.req]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    label="Naziv"
                    v-model="name"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                class="pt-0 mt-0 pb-0 mb-0"
                v-if="pos && pos.type_name === 'VIVA'"
              >
                <v-col class="pt-0 mt-2 pb-0 mb-0">
                  <v-select
                    class="pt-0 mt-0 pb-0 mb-0"
                    outlined
                    dense
                    label="Terminal ID (TID)"
                    v-model="selectedDevice"
                    :items="posDevices"
                    item-text="terminalId"
                    return-object
                    :rules="[rules.req]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row
                class="pt-0 mt-0 pb-0 mb-0"
                v-if="pos && pos.type_name !== 'VIVA'"
              >
                <v-col class="pt-0 mt-2 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    type="number"
                    label="Terminal ID (TID)"
                    v-model="addedDevice"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-2 pb-0 mb-0">
                  <v-select
                    class="pt-0 mt-0 pb-0 mb-0"
                    outlined
                    dense
                    label="Kartice"
                    v-model="selectedCards"
                    :items="cards"
                    item-text="name"
                    return-object
                    multiple
                    :rules="[rules.req]"
                  ></v-select>
                </v-col>
              </v-row>
              <!-- <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field> </v-text-field>
                </v-col>
              </v-row> -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Dodaj uređaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-overlay v-if="loading" absolute z-index="10">
      <v-progress-circular indeterminate size="120" color="primary"
        ><p style="color: white" class="pl-6">
          Dohvaćam uređaje
        </p></v-progress-circular
      >
    </v-overlay>
  </v-dialog>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
// import clone from '@/plugins/utils'

export default {
  components: { },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    loading: false,
    visible: false,
    location: undefined,
    locations: [],
    selectedDevice: undefined,
    cards: [],
    selectedCards: [],
    posDevices: [],
    addedDevice: undefined,
    submitting: false,
    name: undefined,
    type: undefined,
    pos: undefined,
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: []
  }),
  mounted () {
    this.getPointsOfSale()
  },
  methods: {
    getDevices () {
      this.loading = true
      var payload = {
        action: {
          operation: 'get',
          entity: 'devices',
          params: {
            pos_id: this.pos.id,
            pos_type: this.pos.type_name,
            company_id: state.getCurrentCompany().id
          }
        }
      }

      const reqId = uuidv4()
      var userID = auth.currentUser.uid
      if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
        userID = state.getCashRegisterUser().id
      }

      const docRef = df.doc(`response/${reqId}`)
      const unsubscribe = docRef.onSnapshot((doc) => {
        if (doc.data()) {
          unsubscribe()
          var response = doc.data()
          if (response.body) {
            if (response.body.devices) {
              this.loading = false
              this.posDevices = response.body.devices
            }

            if (response.body.code && response.body.code !== 200 && response.body.error && response.body.error !== '') {
              this.loading = false

              this.showMsgBox({
                text: response.body.error,
                actions: ['cancel'],
                cancelBtnText: this.$t('$vuetify.close'),
                color: 'error'
              })
            }

            this.loading = false
          }
        }
      })

      df.doc(`request/${reqId}`)
        .set({
          user_id: `${userID}`,
          device_id: 'web',
          created: `${new Date().getTime()}`,
          type: 'cardpos',
          payload: btoa(
            unescape(encodeURIComponent(JSON.stringify(payload)))
          )
        })
        .catch((err) => {
          this.showMsgBox({
            text: err && err !== '' ? err : 'An error has occurred',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
        })

      this.hideLoader()
    },
    async getPointsOfSale () {
      var that = this
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          that.locations.push(location)
        })
      })

      df
        .doc(`company_cards/${state.getCurrentCompany().id}`)
        .onSnapshot((doc) => {
          if (
            doc &&
             doc.data() &&
             doc.data().cards &&
           doc.data().cards.length > 0
          ) {
            that.cards = doc.data().cards.filter(card => card.active === true)
          }
          that.cards = that.cards.sort((a, b) => a.name.localeCompare(b.name))
        })
    },
    open (pos, location) {
      this.pos = pos
      this.location = location
      this.visible = true
      this.submitting = false

      if (this.pos.type_name === 'VIVA') {
        this.getDevices()
      }
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.type = undefined
      this.visible = false
      this.location = undefined
    },
    async submit () {
      if (!this.$refs.addPosDeviceForm.validate()) {
        return
      }
      const tblId = uuidv4()
      const docRef = df.doc(`location_poses/${this.location.id}/poses/${tblId}`)

      var deviceID = ''
      if (this.pos.type_name === 'VIVA') {
        deviceID = this.selectedDevice.terminalId
      } else {
        deviceID = this.addedDevice
      }

      var posDevice = {
        id: tblId,
        type_name: this.pos.type_name,
        type_id: this.pos.type_id,
        pos_id: this.pos.id,
        name: this.name,
        device_id: deviceID,
        status: 'ACTIVATED',
        cards: this.selectedCards
      }

      try {
        await docRef.set(
          posDevice
        )
      } catch (err) {
        console.error('Error saving pos: ', err)
      }

      this.setPosDeviceToCashRegisters(posDevice)

      this.$emit('added', this.location)
      this.resetInputs()
    },
    setPosDeviceToCashRegisters (posDevice) {
      if (this.location.id !== '') {
        df.collection('cash_registers')
          .where('location_id', '==', this.location.id)
          .get()
          .then((documentSnapshots) => {
            if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
              documentSnapshots.docs.forEach((rec) => {
                var cashReg = rec.data()
                if (cashReg.id !== '') {
                  this.updateCashRegister(cashReg, posDevice)
                }
              })
            }
          })
      }
    },
    updateCashRegister (cashReg, posDevice) {
      var that = this
      try {
        const reqId = uuidv4()
        this.$forceUpdate()
        const payload = {
          action: {
            resource_id: cashReg.id,
            operation: 'update',
            entity: 'cash_register',
            params: {
              company_id: state.getCurrentCompany().id,
              location_id: cashReg.location_id,
              terminals: [posDevice],
              update_terminals: true
            }
          }
        }

        df.doc(`request/${reqId}`).set(
          {
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'cashregister',
            payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
          }
        ).then((doc) => {
        }).catch(function (error) {
          // console.error('Error writing document: ', error)
          that.showMsgBox({ text: error || 'Internal error', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        }).finally(() => {
        })
      } catch (error) {
        that.showMsgBox({ text: error || 'An error has occurred', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
      }
    }
  }

}
</script>
