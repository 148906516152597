<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    max-width="330"
    style="z-index: 6"
  >
    <v-form ref="addPosDeviceForm" @submit.prevent="submit">
      <v-card :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Terminal</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="flex-wrap justify-center" v-if="device">
            <v-col>
              <v-row class="pt-0 mt-2 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    label="Naziv"
                    v-model="device.name"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    label="Terminal ID (TID)"
                    v-model="device.device_id"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-2 pb-0 mb-0">
                  <v-select
                    class="pt-0 mt-0 pb-0 mb-0"
                    outlined
                    dense
                    label="Kartice"
                    v-model="device.cards"
                    :items="cards"
                    item-text="name"
                    return-object
                    multiple
                    :rules="[rules.req]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-2 pb-0 mb-0">
                  <v-select
                    class="pt-0 mt-0 pb-0 mb-0"
                    outlined
                    dense
                    label="Status"
                    v-model="device.status"
                    :items="statuses"
                    item-text="name"
                    item-value="value"
                    :rules="[rules.req]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi uređaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-overlay v-if="loading" absolute z-index="10">
      <v-progress-circular indeterminate size="120" color="primary"
        ><p style="color: white" class="pl-6">
          Dohvaćam uređaje
        </p></v-progress-circular
      >
    </v-overlay>
  </v-dialog>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import { v4 as uuidv4 } from 'uuid'
import { dateTimeToUnixTimestamp } from '@/libs/receiptIssuing/commons'
import state from '@/state'
// import clone from '@/plugins/utils'

export default {
  components: { },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    loading: false,
    visible: false,
    device: undefined,
    cards: [],
    selectedCards: [],
    submitting: false,
    name: undefined,
    type: undefined,
    pos: undefined,
    updateRegs: undefined,
    statuses: [{ name: 'AKTIVAN', value: 'ACTIVATED' }, { name: 'NEAKTIVAN', value: 'DEACTIVATED' }],
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: []
  }),
  mounted () {
    const that = this
    df
      .doc(`company_cards/${state.getCurrentCompany().id}`)
      .onSnapshot((doc) => {
        if (
          doc &&
            doc.data() &&
            doc.data().cards &&
          doc.data().cards.length > 0
        ) {
          that.cards = doc.data().cards.filter(card => card.active === true)
        }
        that.cards = that.cards.sort((a, b) => a.name.localeCompare(b.name))
      })
  },
  methods: {
    open (pos, location, device) {
      this.location = location
      this.pos = pos
      this.device = device
      this.visible = true
      this.submitting = false
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.type = undefined
      this.visible = false
      this.location = undefined
    },
    async submit () {
      const docRef = df.doc(`location_poses/${this.location.id}/poses/${this.device.id}`)

      try {
        await docRef.update(
          {
            name: this.device.name,
            status: this.device.status,
            device_id: this.device.device_id,
            cards: this.device.cards
          }
        )

        this.updateRegs = []

        df.collection('cash_registers')
          .where(`terminals.${this.device.id}.id`, '==', this.device.id)
          .onSnapshot((doc) => {
            doc.docs.forEach((rec) => {
              var cReg = rec.data()
              const rID = uuidv4()
              const pl = {
                action: {
                  resource_id: cReg.id,
                  operation: 'update',
                  entity: 'cash_register',
                  params: {
                    company_id: cReg.company_id,
                    location_id: cReg.location_id,
                    terminals: [{
                      cards: this.device.cards,
                      device_id: this.device.device_id,
                      id: this.device.id,
                      name: this.device.name,
                      status: this.device.status,
                      pos_id: this.pos.id,
                      type_id: this.pos.type_id,
                      type_name: this.pos.type_name
                    }],
                    update_terminals: true
                  }
                }
              }

              const userId = auth.currentUser.uid

              df.doc(`request/${rID}`)
                .set({
                  user_id: `${userId}`,
                  device_id: 'web',
                  created: `${dateTimeToUnixTimestamp(new Date())}`,
                  type: 'cashregister',
                  payload: btoa(
                    unescape(encodeURIComponent(JSON.stringify(pl)))
                  )
                })
                .catch((err) => {
                  this.showMsgBox({
                    text: err && err !== '' ? err : 'An error has occurred',
                    actions: ['cancel'],
                    cancelBtnText: this.$t('$vuetify.close'),
                    color: 'error'
                  })
                })
            })
          })
      } catch (err) {
        console.error('Error saving pos: ', err)
      }

      this.resetInputs()
      this.$emit('addedDevice', true)
    }
  }

}
</script>
