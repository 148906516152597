var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"z-index":"6"},attrs:{"persistent":"","scrollable":"","max-width":"300"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-form',{ref:"addPosForm",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',{staticClass:"overflow-auto",class:{ 'mobile-card': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-card-title',{staticClass:"d-flex",staticStyle:{"vertical-align":"middle"}},[_c('v-row',{staticClass:"pa-3 mb-2"},[_c('h3',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.type && _vm.type === "VIVA" ? "POS API vjerodajnice" : "POS")+" ")]),_c('v-spacer'),_c('v-icon',{attrs:{"large":""},on:{"click":_vm.close}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"flex-wrap justify-center"},[_c('v-col',[_c('v-row',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-col',{staticClass:"pt-0 mt-2 pb-0 mb-0"},[_c('v-select',{staticClass:"pt-0 mt-0 pb-0 mb-0",attrs:{"outlined":"","dense":"","label":"Tip POS-a","items":_vm.poses,"item-text":"name","return-object":"","rules":[_vm.rules.req]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),(_vm.type && _vm.type.name !== 'TABLEPOP')?_c('v-row',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-col',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-text-field',{staticClass:"pt-0 mt-0 pb-0 mb-0",attrs:{"dense":"","outlined":"","label":_vm.type && _vm.type.name === 'VIVA'
                      ? 'ID Klijenta'
                      : _vm.type.name === 'MYPOS'
                      ? 'Korisničko ime'
                      : '',"rules":[_vm.rules.req]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1):_vm._e(),(_vm.type && _vm.type.name !== 'TABLEPOP')?_c('v-row',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-col',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-text-field',{staticClass:"pt-0 mt-0 pb-0 mb-0",attrs:{"dense":"","outlined":"","label":_vm.type && _vm.type.name === 'VIVA'
                      ? 'Tajni ključ'
                      : _vm.type.name === 'MYPOS'
                      ? 'Lozinka'
                      : '',"rules":[_vm.rules.req]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1):_vm._e(),(_vm.type && _vm.type.name === 'VIVA')?_c('v-row',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-col',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-text-field',{staticClass:"pt-0 mt-0 pb-0 mb-0",attrs:{"dense":"","outlined":"","label":"Webhook ključ"},model:{value:(_vm.webhookKey),callback:function ($$v) {_vm.webhookKey=$$v},expression:"webhookKey"}})],1)],1):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-center pb-5"},[_c('v-btn',{staticClass:"white--text okButton",attrs:{"height":"45","loading":_vm.submitting,"disabled":_vm.submitting,"type":"submit"}},[_vm._v(" Dodaj POS ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }